import React, { useEffect, useMemo, useState } from 'react'
import { 
  Button, 
  Grid, 
  IconButton, 
  ListItem, 
  Tooltip, 
  Typography 
} from '@mui/material';
import { 
  DocumentListGrid, 
  FileListGrid, 
  InnerGridBackgroundContainer 
} from './styled';
import FolderListItem from '../../components/FolderListItem';
import DocumentListItem from '../../components/DocumentListItem';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { retrieveCollaboratorUsersInfo } from '../../actions/collaboratorActions';
import { updateGptMessage } from '../../actions/gptChatActions';
import { 
  MESSAGES_COLLECTION_SAVE_SUCCESS, 
  UPDATE_TRIAL_GPT_MESSAGE_SUCCESS 
} from '../../consts/gptChatConstants';
import { augmentSharedWithCollaboratorInfo } from './helpers';
import DocDetailView from './DocDetailView';
import FolderControls from '../../components/FolderControls';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@emotion/react';
import FolderControlsMobile from '../../components/FolderControlsMobile';
import FolderCollaboratorsDialog from '../../components/FolderControls/FolderCollaboratorsDialog';

function FilesViewMobile({
  selectedFolder,
  setSelectedFolder,
  filteredDocs,
  ownedMessageFolders,
  sharedMessageFolders,
  managerView,
  selectedFileData,
  setSelectedFileData,
  setManagerView,
  handleDeleteFile,
  handleDeleteFolder
}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { 
    isAuthenticated, 
    loginWithRedirect 
  } = useAuth0();

  const userId = useSelector((state) => state?.userData?.loginInfo?.user_id);
  const allUsersCollaborators = useSelector((state) => state?.collaborators?.retrievedCollaborators);

  const [editDoc, setEditDoc] = useState(false);
  const [documentContent, setDocumentContent] = useState(null);
  const [documentName, setDocumentName] = useState(null);
  const [documentFolderId, setDocumentFolderId] = useState(null);
  const [documentChatHistory, setDocumentChatHistory] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [docCollaboratorChats, setDocCollaboratorChats] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const ownedFolderIds = ownedMessageFolders?.map((folder) => folder?._id);

  const collaboratorsUserInfo = () => {
    const collaboratorsIds = selectedFileData?.sharedWith?.map(
      (user) => user.user_id
    );

    const filterUnfetchedCollaborators = (ids, allUsers) => {
      return ids.filter(id => !allUsers.some(user => user.user_id === id));
    };

    const missingCollaboratorIds = filterUnfetchedCollaborators(
      collaboratorsIds, 
      allUsersCollaborators
    );

    if(missingCollaboratorIds.length){
      dispatch(retrieveCollaboratorUsersInfo(missingCollaboratorIds, dispatch));
    };
  };

  const handleSaveCollaborators = (updatedCollaborators) => {
    setCollaborators(updatedCollaborators);
  };

  const handleContinueConversation = () => {
    dispatch({ 
      type: MESSAGES_COLLECTION_SAVE_SUCCESS, 
      payload: selectedFileData?.savedMessageHistory
    });

    if(isAuthenticated){
      navigate('/app-home');
    } else {
      navigate('/');
    };
  };
  
  const handleUpdateContent = async() => {

    if(isAuthenticated){
    
      dispatch(
        updateGptMessage(
          {
            content: documentContent, 
            folderId: documentFolderId, 
            docName: documentName, 
            messageId: selectedFileData?._id
          }
        )
      );

      setEditDoc(false);
       
    } else {
      dispatch({
        type: UPDATE_TRIAL_GPT_MESSAGE_SUCCESS,
        payload: {
          content: documentContent,
          folderId: documentFolderId,
          docName: documentName,
          savedMessageHistory: documentChatHistory,
          _id: documentId
        }
      });

      setEditDoc(false)
    };
  };
  
  const userIsOwner = () => {
    if(selectedFileData?.ownerId !== userId){
      return false;
    };

    return true;
  };

  const userCanEdit = () => {
    const userInMessageObject = selectedFileData?.sharedWith?.filter((user) => user?.user_id === userId);
    if(userInMessageObject){
      const userPermission = userInMessageObject[0]?.permission;

      if(userPermission === 'canEdit'){
        return true;
      };
    };

    return false;
  };

  useMemo(() => {
    if(selectedFileData){
      setDocumentContent(selectedFileData?.content);
      setDocumentName(selectedFileData?.docName);
      setDocumentFolderId(selectedFileData?.folderId);
      setDocumentChatHistory(selectedFileData?.savedMessageHistory);
      setDocumentId(selectedFileData?._id);
      setDocCollaboratorChats(selectedFileData?.collabChats);
    };

  }, [selectedFileData]);

  // Whenever new collaborators are added,
  // this adds enriched user data for display from the
  // collaboratorsUserInfo() method result
  useMemo(() => {

    if(allUsersCollaborators && selectedFileData?.sharedWith){

      const updatedFileData = augmentSharedWithCollaboratorInfo(selectedFileData, allUsersCollaborators);
      setCollaborators(updatedFileData?.sharedWith);
    };

  }, [allUsersCollaborators, selectedFileData]);

  // Gets any collaborators full userInfo from database for 
  // proper display of data about that user
  useEffect(() => {
    if(selectedFileData?.sharedWith?.length){
      collaboratorsUserInfo();
    };

    // eslint-disable-next-line
  }, [selectedFileData?.sharedWith]);

  const truncateText = (text) => {
    if(text){
      const maxLength = 13;
      if (text?.length <= maxLength) {
        return text;
      }
      return text.slice(0, maxLength) + '...';
    } else {
      return 'All';
    }
  };

  const handleManagerView = () => {
    switch(managerView){
      case 'Folders':
        return (
          <FileListGrid container sx={{paddingRight: '1rem'}}>
            <InnerGridBackgroundContainer>
              {
                !isAuthenticated && (
                  <ListItem sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button 
                      variant='contained' 
                      color='primary'
                      onClick={() => loginWithRedirect()}
                    >
                      <Typography sx={{color: 'white'}}>
                        <b>Login</b> To Keep Saved Files
                      </Typography>
                    </Button>
                  </ListItem>
                )
              }
              <FolderListItem
                folderName={'All'}
                folderId={0}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder} 
                setManagerView={setManagerView}
              />
              {
                ownedMessageFolders?.map((folder) => (
                  <FolderListItem 
                    key={folder?._id}
                    folderName={folder?.name}
                    folderId={folder?._id}
                    folderData={folder}
                    shared={false}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder} 
                    setManagerView={setManagerView}
                  />
                ))
              }
              {
                sharedMessageFolders?.map((folder) => (
                  <FolderListItem 
                    key={folder?._id}
                    folderName={folder?.name}
                    folderId={folder?._id}
                    folderData={folder}
                    shared={true}
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder} 
                    setManagerView={setManagerView}
                  />
                ))
              }
            </InnerGridBackgroundContainer>
          </FileListGrid>
        )
      case 'Files':
        return (
          <DocumentListGrid container>
            <InnerGridBackgroundContainer>
              <Grid 
                sx={{
                  display: 'flex', 
                  alignItems: 'center', 
                  flex: '1', 
                  width: '100%', 
                  justifyContent: 'space-between',
                  maxHeight: '5rem'
                }}
              >
                <IconButton 
                  sx={{
                    marginTop: '0.5rem', 
                    marginLeft: '1rem',
                    marginRight: '1rem',
                    backgroundColor: 'lightgray'
                  }}
                  onClick={() => setManagerView('Folders')}
                >
                  <ArrowBackOutlinedIcon  
                    sx={{
                      color: 'white', 
                      fontSize: '2rem',
                    }} 
                  />
                </IconButton>
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Tooltip title={selectedFolder?.name}>
                    <Typography
                      variant='h4' 
                      sx={{
                        color: 'gray',
                      }}
                    >
                      {truncateText(selectedFolder?.name)}
                    </Typography>
                  </Tooltip>
                  <IconButton
                    sx={{
                      margin: '1rem',
                      border: `1px solid ${theme.palette.primary.lt}`
                    }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <MoreVertIcon sx={{ color: 'gray' }} />
                  </IconButton>
                </Grid>
                <FolderControlsMobile 
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  // handleEditFolderName={handleEditFolderName}
                  handleDeleteFolder={handleDeleteFolder}
                  setCollaboratorsOpen={setCollaboratorsOpen}
                />
                <FolderCollaboratorsDialog
                  open={collaboratorsOpen}
                  collaborators={selectedFolder?.sharedWith}
                  setCollaboratorsOpen={setCollaboratorsOpen}
                  onSave={handleSaveCollaborators}
                  selectedFileData={selectedFolder}
                />
              </Grid>
              {
                filteredDocs?.map((message) => (
                  <DocumentListItem 
                    messageData={message} 
                    setSelectedFileData={setSelectedFileData}
                    setManagerView={setManagerView}
                    isMobile={true}
                  />
                ))
              }
            </InnerGridBackgroundContainer>
          </DocumentListGrid>
        )
      case 'Document':
        return (
          <DocDetailView 
            setManagerView={setManagerView}
            setEditDoc={setEditDoc}
            navigate={navigate}
            editDoc={editDoc}
            documentFolderId={documentFolderId}
            setDocumentFolderId={setDocumentFolderId}
            userIsOwner={userIsOwner}
            userCanEdit={userCanEdit}
            documentName={documentName}
            setDocumentName={setDocumentName}
            setCollaboratorsOpen={setCollaboratorsOpen}
            collaborators={collaborators}
            collaboratorsOpen={collaboratorsOpen}
            handleSave={handleSaveCollaborators}
            selectedFileData={selectedFileData}
            handleUpdateContent={handleUpdateContent}
            handleContinueConversation={handleContinueConversation}
            handleDeleteFile={handleDeleteFile}
            documentContent={documentContent}
            setDocumentContent={setDocumentContent}
            docCollaboratorChats={docCollaboratorChats}
            documentId={selectedFileData?._id}
          />
        )
      default: return (
        <FileListGrid container>
          <InnerGridBackgroundContainer>
            <FolderListItem
              folderName={'All'}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
            {
              ownedMessageFolders?.map((folder) => (
                <FolderListItem 
                  folderName={'All'}
                  folderId={0}
                  selectedFolder={folder}
                  setSelectedFolder={setSelectedFolder} 
                  setManagerView={setManagerView} 
                />
              ))
            }
          </InnerGridBackgroundContainer>
        </FileListGrid>
      )
    }
  }

  return (
    <>
      {
        handleManagerView()
      }
    </>
  )
}

export default FilesViewMobile;