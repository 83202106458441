import React, { useEffect, useMemo, useState } from 'react'
import { 
  CollabButtonGrid,
  DocumentContentGrid, 
  InnerGridBackgroundContainer, 
  ParentGrid 
} from './styled';
import { 
  Button, 
  Grid, 
  IconButton, 
  TextField, 
  Tooltip, 
  Typography 
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import gtmTrackButtonClick from '../../utils/gtmTrackButtonClick';
import CollaboratorsDialog from '../../containers/DocumentManager/CollabDialog';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { updateFolderName } from '../../actions/documentActions';
import FolderCollaboratorsDialog from './FolderCollaboratorsDialog';
import collaboratorsUserInfo from '../../utils/collaboratorsUserInfo';
import { augmentSharedWithCollaboratorInfo } from '../../containers/DocumentManager/helpers';

function FolderControls({ 
  isMobile, 
  selectedFolder, 
  handleDeleteFolder,
  ownedFolderIds
}) {

  const dispatch = useDispatch();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const allUsersCollaborators = useSelector((state) => state?.collaborators?.retrievedCollaborators);

  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [collaborators, setCollaborators] = useState(selectedFolder?.sharedWith);
  const [folderName, setFolderName] = useState(selectedFolder?.name);
  const [editName, setEditName] = useState(false);

  const userCanEditName = () => {

    if(selectedFolder?._id === 0){
      return false;
    };

    return true;
  };

  const handleUpdateFolderName = () => {
    setEditName(false);

    dispatch(
      updateFolderName(selectedFolder?._id, folderName, dispatch)
    );
  };

  const cancelUpdateFolderName = () => {
    setFolderName(selectedFolder?.name);
    setEditName(false);
  };

  const handleSave = (updatedCollaborators) => {
    setCollaborators(updatedCollaborators);
  };

  useMemo(() => {
    setFolderName(selectedFolder?.name);
    setCollaborators(selectedFolder?.sharedWith);
  }, [selectedFolder]);

  useMemo(() => {

    if(allUsersCollaborators && selectedFolder?.sharedWith){

      const updatedFileData = augmentSharedWithCollaboratorInfo(selectedFolder, allUsersCollaborators);
      setCollaborators(updatedFileData?.sharedWith);
    };

  }, [allUsersCollaborators, selectedFolder]);

  useEffect(() => {
    if(selectedFolder?.sharedWith?.length){
      collaboratorsUserInfo({
        sharedObjectData: selectedFolder,
        allUsersCollaboratorIds: allUsersCollaborators,
        dispatch
      });
    };

    // eslint-disable-next-line
  }, [selectedFolder?.sharedWith]);

  return (
    <ParentGrid container>
      <InnerGridBackgroundContainer container>
        <DocumentContentGrid item>
          <Grid 
            sx={{
              display: 'flex', 
              flex: '1', 
              justifyContent: 'center', 
              alignItems: 'center'
            }}
          >
            <Grid 
              sx={{
                flex: isMobile && '9', 
                height: '100%', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: isMobile ? 'flex-start' : 'center'
              }}
            >
              {
                editName ? (
                  <TextField
                    defaultValue={folderName} 
                    onChange={(e) => setFolderName(e.target.value)}
                    autoFocus
                  />
                ) : (
                  <Typography variant='h5'>
                    {folderName}
                  </Typography>
                )
              }
            </Grid>
            <Grid 
              sx={{
                marginLeft: isMobile && '1rem', 
                display: userCanEditName() ? 'flex' : 'none'
              }}
            >
              {
                editName ? (
                  <Tooltip title='Save Name'>
                    <IconButton 
                      size='small'
                      sx={{
                        marginLeft: isMobile ? '0' : '2rem', 
                        border: '1px solid gray'
                      }}
                      onClick={handleUpdateFolderName}
                    >
                      <SaveIcon sx={{color: 'gray', fontSize: isMobile && '1rem'}} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='Edit Name'>
                    <IconButton 
                      size='small'
                      sx={{
                        marginLeft: isMobile ? '0' : '2rem', 
                        border: '1px solid gray'
                      }}
                      onClick={() => setEditName(true)}
                    >
                      <EditIcon sx={{color: 'gray', fontSize: isMobile && '1rem'}} />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Grid>
            <Grid sx={{ marginLeft: '1rem', display: ownedFolderIds?.includes(selectedFolder?._id) ? 'flex' : 'none' }}>
              {
                editName ? (
                  <Tooltip title='Cancel'>
                    <IconButton 
                      size='small' 
                      sx={{ border: '1px solid gray'}}
                      onClick={cancelUpdateFolderName}
                    >
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='Delete Folder'>
                    <IconButton 
                      size='small' 
                      sx={{ border: '1px solid red'}}
                      onClick={handleDeleteFolder}
                    >
                      <DeleteForeverOutlined sx={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Grid>
          </Grid>
        </DocumentContentGrid>
        <CollabButtonGrid 
          item 
          sx={{ display: selectedFolder?._id === 0 && 'none' }}
        >
          <Grid sx={{ flex: '1' }}>
            {
              isAuthenticated ? (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={(e) => {
                    gtmTrackButtonClick(e, 'click_openCollaborators')
                    setCollaboratorsOpen(true)
                  }}
                  sx={{
                    marginRight: '1rem',
                    background: 'linear-gradient(to left, #507CE6 0%, #84A8FF 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    borderRadius: '2rem'
                  }}
                >
                  <>
                    <svg width={0} height={0}>
                      <linearGradient id="exampleColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(45)">
                        <stop offset='0%' stopColor="#507CE6" />
                        <stop offset='50%' stopColor="#84A8FF" />
                        <stop offset='100%' stopColor="#FED602" />
                      </linearGradient>
                    </svg>
                    <GroupOutlinedIcon sx={{ fill: "url(#exampleColors)", marginRight: '1rem' }} />
                  </>
                  <Typography>
                    Collaborators
                  </Typography>
                </Button>
              ) : (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={(e) => {
                    gtmTrackButtonClick(e, 'click_register_desktop_docView')
                    loginWithRedirect({
                      authorizationParams: { screen_hint: "signup" }
                    })
                  }}
                  sx={{
                    marginRight: '1rem',
                    background: 'linear-gradient(to left, #507CE6 0%, #84A8FF 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    borderRadius: '2rem',
                    width: '12rem'
                  }}
                >
                  <svg width={0} height={0}>
                    <linearGradient id="exampleColors" x1={1} y1={0} x2={1} y2={1} gradientTransform="rotate(45)">
                      <stop offset='0%' stopColor="#507CE6" />
                      <stop offset='50%' stopColor="#84A8FF" />
                      <stop offset='100%' stopColor="#FED602" />
                    </linearGradient>
                  </svg>
                  <GroupOutlinedIcon sx={{ fill: "url(#exampleColors)", marginRight: '1rem' }} />
                  <Typography>
                    Register Free
                  </Typography>
                </Button>
              )
            }
          </Grid>

        </CollabButtonGrid>
        <FolderCollaboratorsDialog
          open={collaboratorsOpen}
          collaborators={collaborators}
          setCollaboratorsOpen={setCollaboratorsOpen}
          onSave={handleSave}
          selectedFolderData={selectedFolder}
          isMobile={isMobile}
        />
      </InnerGridBackgroundContainer>
    </ParentGrid>
  )
}

export default FolderControls;